import React, { useState } from "react";
import { Link } from "react-router-dom";

const AdminMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Fonction pour gérer l'ouverture et la fermeture du menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      {/* Bouton triangle visible sur petits écrans */}
      <button
        className="text-white p-2 bg-gray-800 lg:hidden"
        onClick={toggleMenu}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d={
              isMenuOpen
                ? "M19 9l-7 7-7-7" // Triangle pointant vers le haut quand le menu est ouvert
                : "M5 15l7-7 7 7" // Triangle pointant vers le bas quand le menu est fermé
            }
          />
        </svg>
      </button>

      {/* Menu Admin - masqué sur petits écrans sauf si ouvert */}
      <div
        className={`w-64 h-screen bg-gray-800 text-white p-4 fixed top-0 left-0 transform ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out lg:translate-x-0 z-20`}
      >
        <h2 className="text-2xl font-bold mb-4">Admin Menu</h2>
        <ul>
          <li className="mb-2">
            <Link
              to="/admin/users"
              className="text-white hover:underline"
              onClick={toggleMenu} // Ferme le menu après clic
            >
              Manage Users
            </Link>
          </li>
          <li className="mb-2">
            <Link
              to="/admin/orders"
              className="text-white hover:underline"
              onClick={toggleMenu} // Ferme le menu après clic
            >
              Manage Orders
            </Link>
          </li>
          <li className="mb-2">
            <Link
              to="/admin/products"
              className="text-white hover:underline"
              onClick={toggleMenu} // Ferme le menu après clic
            >
              Manage Products
            </Link>
          </li>
        </ul>
      </div>

      {/* Overlay quand le menu est ouvert sur petits écrans */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 lg:hidden z-10"
          onClick={toggleMenu}
        ></div>
      )}
    </div>
  );
};

export default AdminMenu;
